import { ref, triggerRef } from '@vue/composition-api'
import { useRouter } from '@core/utils/utils'
import { $themeColors } from '@themeConfig'
import api from '../services/api'

export default function () {
  const { router } = useRouter()
  const total = ref(0)

  const dados = ref({
    series: [0, 0, 0],
    chartOptions: {
      labels: ['Regular', 'Sem situação fiscal', 'Irregular'],
      plotOptions: {
        radialBar: {
          size: 150,
          hollow: {
            size: '20%',
          },
          track: {
            strokeWidth: '100%',
            margin: 15,
          },
          dataLabels: {
            value: {
              fontSize: '1rem',
              colors: '#5e5873',
              fontWeight: '500',
              offsetY: 5,
              formatter(val) {
                return val
              },
            },
            total: {
              show: true,
              label: 'Total',
              fontSize: '1.286rem',
              colors: '#5e5873',
              fontWeight: '500',

              formatter() {
                // By default this function returns the average of all series. The below is just an example to show the use of custom formatter function
                return total.value
              },
            },
          },
        },
      },
      fill: {
        type: 'gradient',
        gradient: {
          shade: 'dark',
          type: 'vertical',
          gradientToColors: ['#28c76f', '#ffe06f', '#f58e8e'],
          stops: [0, 100],
        },
      },
      colors: ['#4D9F72', $themeColors.warning, $themeColors.danger],
      stroke: {
        lineCap: 'round',
      },
      chart: {
        height: 355,
        dropShadow: {
          enabled: true,
          blur: 3,
          left: 1,
          top: 1,
          opacity: 0.1,
        },
        events: {
          dataPointSelection(event, chartContext, grafico) {
            const nome = dados.value.chartOptions.labels[grafico.dataPointIndex]

            if (nome === 'Regular') {
              router.push({ path: 'situacao-fiscal/visualizar', query: { filtroRapido: 'Regular' } })
            } else if (nome === 'Irregular') {
              router.push({ path: 'situacao-fiscal/visualizar', query: { filtroRapido: 'Irregular' } })
            } else if (nome === 'Sem situação fiscal') {
              router.push({ path: 'situacao-fiscal/visualizar', query: { filtroRapido: 'SemSituacao' } })
            }
          },
          click(event) {
            const eixoX = event.toElement.parentNode.innerHTML
            const inHtml = event.toElement.innerHTML

            if (eixoX.includes('Total') && inHtml === '') {
              router.push({ path: 'situacao-fiscal/visualizar' })
            }
          },
        },
      },
    },
    chartInfo: {
      Total: 0,
      Regular: 0,
      'Sem Situação Fiscal': 0,
      Irregular: 0,
    },
  })

  api.getContadoresSituacaoFiscal()
    .then(payload => {
      const handler = []
      handler.push(payload.data.totalRegular)
      handler.push(payload.data.totalSemSituacao)
      handler.push(payload.data.totalIrregular)
      dados.value.chartInfo.Total = payload.data.total
      dados.value.chartInfo['Sem Situação Fiscal'] = payload.data.totalSemSituacao
      dados.value.chartInfo.Regular = payload.data.totalRegular
      dados.value.chartInfo.Irregular = payload.data.totalIrregular
      total.value = payload.data.total
      dados.value.series = handler
      triggerRef(dados)
    })

  return {
    dadosSituacaoFiscal: dados,
  }
}
