import { useRouter } from '@core/utils/utils'
import { ref } from '@vue/composition-api'
import api from '../services/api'

export default function () {
  const { router } = useRouter()

  const dados = ref({
    series: [{
      name: 'Em dia',
      data: [],
      // color: 'rgba(25, 48, 81, 0.75)',
      color: 'rgba(77, 159, 114, 0.75)',
    },
    {
      name: 'Em atraso',
      data: [],
      color: 'rgba(255, 94, 110)',
    }],
    chartOptions: {
      chart: {
        stacked: true,
        events: {
          dataPointSelection(event, chartContext, grafico) {
            const eixoX = grafico.w.config.series[grafico.seriesIndex].data[grafico.dataPointIndex].x
            const filtroAtrasados = grafico.w.config.series[grafico.seriesIndex].name === 'Em atraso'

            if (eixoX === 'DAS') {
              router.push({ path: '/parcelamento/das/visualizar', query: { filtroRapido: filtroAtrasados ? 'StatusEmAtraso' : 'StatusSemAtraso' } })
            } else if (eixoX === 'PERT SN') {
              router.push({ path: '/parcelamento/pertsn/visualizar', query: { filtroRapido: filtroAtrasados ? 'StatusEmAtraso' : 'StatusSemAtraso' } })
            } else if (eixoX === 'RELP SN') {
              router.push({ path: '/parcelamento/relpsn/visualizar', query: { filtroRapido: filtroAtrasados ? 'StatusEmAtraso' : 'StatusSemAtraso' } })
            } else if (eixoX === 'RFB') {
              router.push({ path: '/parcelamento/rfb/visualizar', query: { filtroRapido: filtroAtrasados ? 'StatusEmAtraso' : 'StatusSemAtraso' } })
            } else if (eixoX === 'Não Previdenciário') {
              router.push({ path: '/parcelamento/nao-previdenciario/visualizar', query: { filtroRapido: filtroAtrasados ? 'StatusEmAtraso' : 'StatusSemAtraso' } })
            } else if (eixoX === 'Simplificado Previdenciário') {
              router.push({ path: '/parcelamento/simplificado-previdenciario/visualizar', query: { filtroRapido: filtroAtrasados ? 'StatusEmAtraso' : 'StatusSemAtraso' } })
            }
          },
          click(event) {
            const tipoParcelamento = event.toElement.innerHTML
            if (tipoParcelamento) {
              if (tipoParcelamento === 'Simples Nacional') {
                router.push('/parcelamento/das/visualizar')
              } else if (tipoParcelamento === 'PERT SN') {
                router.push('/parcelamento/pertsn/visualizar')
              } else if (tipoParcelamento === 'RELP SN') {
                router.push('/parcelamento/relpsn/visualizar')
              } else if (tipoParcelamento === 'RFB') {
                router.push('/parcelamento/rfb/visualizar')
              } else if (tipoParcelamento === 'Não Previdenciário') {
                router.push('/parcelamento/nao-previdenciario/visualizar')
              } else if (tipoParcelamento === 'Simplificado Previdenciá...') {
                router.push('/parcelamento/simplificado-previdenciario/visualizar')
              }
            }
          },
        },
      },
      xaxis: {
        categories: ['Simples Nacional', 'PERT SN', 'RELP SN', 'RFB', 'Não Previdenciário', 'Simplificado Previdenciário'],
        labels: {
          show: false,
        },
      },
      plotOptions: {
        bar: {
          horizontal: true,
          borderRadius: 5,
        },
      },
      tooltip: {
        x: {
          show: false,
        },
      },
    },
  })

  api.getContadoresParcelamentosAtrasados()
    .then(payload => {
      const parcelas = payload.data

      dados.value.series = [{
        name: 'Em dia',
        data: [{ x: 'DAS', y: parcelas.das.emAndamento }, { x: 'PERT SN', y: parcelas.pertSn.emAndamento },
          { x: 'RELP SN', y: parcelas.relpSn.emAndamento }, { x: 'RFB', y: parcelas.rfb.emAndamento },
          { x: 'Não Previdenciário', y: parcelas.naoPrevidenciario.emAndamento }, { x: 'Simplificado Previdenciário', y: parcelas.simplificadoPrevidenciario.emAndamento }],
      },
      {
        name: 'Em atraso',
        data: [{ x: 'DAS', y: parcelas.das.atrasados }, { x: 'PERT SN', y: parcelas.pertSn.atrasados },
          { x: 'RELP SN', y: parcelas.relpSn.atrasados }, { x: 'RFB', y: parcelas.rfb.atrasados },
          { x: 'Não Previdenciário', y: parcelas.naoPrevidenciario.atrasados }, { x: 'Simplificado Previdenciário', y: parcelas.simplificadoPrevidenciario.atrasados }],
      }]
    })

  return {
    dadosParcStatus: dados,
  }
}
