import axios from '@axios'

const PREFIX = '/analiticos'

export default {

  getContadoresEmpresa() {
    return axios.get(`${PREFIX}/totalizadores/empresa`)
  },

  getContadoresCaixaPostal() {
    return axios.get(`${PREFIX}/totalizadores/caixa-postal`)
  },

  getContadoresCnd() {
    return axios.get(`${PREFIX}/totalizadores/cnd`)
  },

  getContadoresSituacaoFiscal() {
    return axios.get(`${PREFIX}/totalizadores/situacao-fiscal`)
  },

  getContadoresPendenciaSituacaoFiscal() {
    return axios.get(`${PREFIX}/totalizadores/pendencia-situacao-fiscal`)
  },

  getContadoresParcelamentosAtrasados() {
    return axios.get(`${PREFIX}/totalizadores/status-parcelamentos`)
  },
  getContadoresDctfWeb(ano) {
    return axios.get(`${PREFIX}/totalizadores/dctf-web?ano=${ano}`)
  },
}
