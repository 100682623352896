<template>
  <b-row>
    <b-col cols="12">
      <totalizadores-empresa />
      <b-row align-v="stretch">
        <b-col cols="4">
          <b-card
            no-body
          >
            <b-card-header>
              <h4 class="mb-0">
                Situação fiscal
              </h4>
            </b-card-header>

            <b-card-body
              style="padding-bottom: 0.7rem;"
            >
              <!-- apex chart -->
              <vue-apex-charts
                type="radialBar"
                height="325"
                :options="situacaoFiscalData.chartOptions"
                :series="situacaoFiscalData.series"
              />
              <!-- chart info -->
              <div
                v-for="(data,key,index) in situacaoFiscalData.chartInfo"
                :key="key"
                class="d-flex justify-content-between"
                :class="index === Object.keys(situacaoFiscalData.chartInfo).length - 1 ? '':'mb-1'"
                @click.stop="redirecionaGrid(index)"
              >
                <div
                  class="series-info d-flex align-items-center"
                  style="cursor: pointer"
                >
                  <font-awesome-icon
                    icon="fa-solid fa-circle"
                    :class="key === 'Total' ? 'text-primary': key === 'Regular' ? 'text-success': key==='Sem Situação Fiscal'?'text-warning':'text-danger'"
                  />
                  <span
                    style="font-size: 16px"
                    class="font-weight-bold text-capitalize ml-75"
                  >{{ key }}</span>
                </div>

                <span style="font-size: 18px">{{ data }}</span>
              </div>
            </b-card-body>
          </b-card>
        </b-col>
        <b-col cols="8">
          <b-card
            no-body
          >
            <b-card-header>
              <h4 class="mb-0">
                Pendências Situação Fiscal
              </h4>
            </b-card-header>

            <b-card-body
              style="padding-bottom: 0.7rem;"
            >
              <b-card-body>
                <vue-apex-charts
                  type="bar"
                  height="380"
                  :options="pendenciaSituacaoFiscalData.chartOptions"
                  :series="pendenciaSituacaoFiscalData.series"
                />
              </b-card-body>
            </b-card-body>
          </b-card>
        </b-col>
      </b-row>
      <b-row>
        <b-col
          v-if="isDemo"
          :cols="permissoes.includes('parcelamento') ? '6' : '12'"
        >
          <b-card
            no-body
          >
            <b-card-header>
              <b-card-title>DCTFWeb</b-card-title>
              <h5>{{ dctfWebData.total }} Declarações</h5>
              <div>
                <b-select
                  v-model="dctfWebAno"
                  name="filtroSelecao"
                  class="search-items"
                  style="float:right;"
                  @select="placeholder = false"
                  @input="atualizarDashDctfWeb"
                >
                  <option
                    v-for="(filtro, index) in anosDctfWeb"
                    :key="index"
                    :value="filtro"
                  >
                    {{ filtro }}
                  </option>
                </b-select>
              </div>
            </b-card-header>
            <b-card-body>
              <vue-apex-charts
                type="bar"
                :options="dctfWebData.chartOptions"
                :series="dctfWebData.series"
                height="285"
              />
            </b-card-body>
          </b-card>
        </b-col>
        <b-col v-else>
          <totalizadores-caixapostal />
        </b-col>
        <b-col v-if="permissoes.includes('parcelamento')" :cols="isDemo ? 6 : 12">
          <b-card no-body>
            <b-card-header>
              <b-card-title>Parcelamentos</b-card-title>
              <b-badge
                v-if="showAvisoConfirmacao"
                :variant="'light-danger'"
              >
                RELP: Confirmação Remanescentes
              </b-badge>
            </b-card-header>
            <b-card-body>
              <vue-apex-charts
                type="bar"
                :options="parcStatusData.chartOptions"
                :series="parcStatusData.series"
                height="300"
              />
            </b-card-body>
          </b-card>
        </b-col>
      </b-row>
      <b-row align-v="stretch">
        <b-col
          v-if="isDemo"
          :cols="permissoes.includes('cnd_estadual') ? '4' : '6'"
          style="max-height: 418;"
        >
          <totalizadores-caixapostal />
        </b-col>
        <b-col
          :cols="permissoes.includes('cnd_estadual') ? '8' : '6'"
        >
          <b-card
            class="card-transaction grafico-view"
            no-body
          >
            <b-card-header>
              <h4>
                CNDs
              </h4>
            </b-card-header>

            <b-card-body
              style="padding-bottom: 0.7rem;"
            >
              <b-card-body>
                <vue-apex-charts
                  type="bar"
                  :options="cndsData.chartOptions"
                  :series="cndsData.series"
                  height="418"
                />
              </b-card-body>
            </b-card-body>
          </b-card>
        </b-col>
      </b-row>
    </b-col>
  </b-row>
</template>

<style lang="scss" >
.swal-m {
  width: 35%;
}

.yaxis-espacamento-titulo {
  letter-spacing: 0.125rem;
}
</style>

<script>
// import { isDemo } from '@/auth/utils'
import { defineComponent } from '@vue/composition-api'
import VueApexCharts from 'vue-apexcharts'
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'
// eslint-disable-next-line
import { useRouter } from '@core/utils/utils'
// eslint-disable-next-line
import useParcAtrasado from './shared/components/useParcelamentoStatus'
// eslint-disable-next-line
import useSituacaoFiscal from './shared/components/useSituacaoFiscal'
// eslint-disable-next-line
import useDctfWeb from './shared/components/useDctfWeb'
// eslint-disable-next-line
import useCnds from './shared/components/useCnds'
// eslint-disable-next-line
import usePendenciaSituacaoFiscal from './shared/components/usePendenciaSituacaoFiscal'
import './shared/style/analitico.scss'
import api from './shared/services/api'

export default defineComponent({
  components: {
    VueApexCharts,
    FontAwesomeIcon,
    TotalizadoresEmpresa: () => import('./shared/components/totalizadores-empresa/TotalizadoresEmpresa.vue'),
    TotalizadoresCaixapostal: () => import('./shared/components/totalizadores-caixapostal/TotalizadoresCaixaPostal.vue'),
  },
  computed: {
    showAvisoConfirmacao() {
      let dataLimite = new Date()
      dataLimite = dataLimite.setMonth(1, 28)
      dataLimite += 60

      return new Date() < dataLimite
    },

    anosDctfWeb() {
      const currentYear = new Date().getFullYear()
      const years = []
      let startYear = 2018

      while (startYear <= currentYear) {
        years.push(startYear)
        startYear += 1
      }
      return years
    },
  },
  created() {
    if (this.$route.params.primeiroAcesso) {
      /*
      this.$swal({
        title: '<div class="text-secondary">Prezado Cliente</div>',
        html: '<div class="text-primary"> Gostaríamos de informar que o sistema recebeu uma atualização para melhor atende-lo. E será necessário efetuar uma configuração por empresa informando quais são as rotinas e parcelamentos que sua empresa tem junto ao e-CAC. <br><br> Acesse o menu <b><a href="empresas/visualizar"><u>Cadastros > Empresas</u></a></b> na coluna <b>Ação</b> clique no ícone <b>"Configuração rotinas e-CAC por empresa"</b> e selecione os parcelamentos e rotinas que devem ser executadas. É necessário selecionar apenas as rotinas existentes em cada empresa para melhor desempenho da ferramenta. Rotinas não configuradas refletem na não geração de informações.</div><br><div class="text-secondary"> Agradecemos a sua atenção e estamos à disposição em caso de dúvidas.<br> Atenciosamente, Equipe Monitor Contábil </div>',
        icon: 'info',
        iconColor: '#ed7d3e',
        showConfirmButton: false,
        customClass: {
          popup: 'swal-m',
        },
      })
      */
    }
  },
  data() {
    return {
      permissoes: localStorage.getItem('permissoes'),
    }
  },
  methods: {
    atualizarDashDctfWeb() {
      api.getContadoresDctfWeb(this.dctfWebAno)
        .then(payload => {
          this.dctfWebData.series = [{
            name: 'Ativa',
            data: payload.data.dctfWebAtivos,
          }, {
            name: 'Em Andamento',
            data: payload.data.dctfWebEmAndamento,
          },
          {
            name: 'Outros',
            data: payload.data.dctfWebOutros,
          }]
          this.dctfWebData.total = payload.data.total
        })
    },
  },
  setup() {
    const { router } = useRouter()

    const { dadosParcStatus } = useParcAtrasado()
    const { dadosSituacaoFiscal } = useSituacaoFiscal()
    const { dadosPendenciaSituacaoFiscal } = usePendenciaSituacaoFiscal()
    const { dadosCnds } = useCnds()
    const { dadosDctfWebStatus, anoDash } = useDctfWeb(new Date().getFullYear())

    const redirecionaGrid = index => {
      if (index === 0) {
        router.push({ name: 'situacaofiscal-view' })
      } else if (index === 1) {
        router.push({ name: 'situacaofiscal-view', param: { query: true }, query: { filtroRapido: 'Regular' } })
      } else if (index === 2) {
        router.push({ name: 'situacaofiscal-view', param: { query: true }, query: { filtroRapido: 'SemSituacao' } })
      } else if (index === 3) {
        router.push({ name: 'situacaofiscal-view', param: { query: true }, query: { filtroRapido: 'Irregular' } })
      }
    }
    const isDemo = true

    return {
      parcStatusData: dadosParcStatus,
      situacaoFiscalData: dadosSituacaoFiscal,
      pendenciaSituacaoFiscalData: dadosPendenciaSituacaoFiscal,
      cndsData: dadosCnds,
      dctfWebData: dadosDctfWebStatus,
      dctfWebAno: anoDash,
      redirecionaGrid,
      isDemo,
    }
  },
})
</script>
