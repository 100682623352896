import { ref } from '@vue/composition-api'
import { useRouter } from '@core/utils/utils'
import api from '../services/api'

export default function () {
  const { router } = useRouter()
  // eslint-disable-next-line no-use-before-define
  const colunas = getColunas()
  // eslint-disable-next-line no-use-before-define
  const labels = getLabels()

  const dados = ref({
    series: [{
      name: 'Motivo',
      data: [],
      color: 'rgba(49, 94, 158)',
    },
    {
      name: 'Regular',
      data: [],
      color: 'rgba(77, 159, 114, 0.75)',
    },
    {
      name: 'Irregular',
      data: [],
      color: 'rgba(255, 94, 110)',
    }],

    chartOptions: {
      label: labels,
      chart: {
        type: 'bar',
        height: 100,
        stacked: true,
        toolbar: {
          show: true,
        },
        zoom: {
          enabled: true,
        },
        events: {
          dataPointSelection(event, chartContext, grafico) {
            const nome = dados.value.chartOptions.label[grafico.dataPointIndex]
            const status = dados.value.series[grafico.seriesIndex].name

            if (nome !== undefined && status !== undefined) {
              router.push({ path: `${nome}/visualizar`, query: { filtroRapido: status } })
            }
          },
          click(event) {
            const valorSelec = dados.value.chartOptions.xaxis.categories.indexOf(event.toElement.innerHTML)
            const nome = dados.value.chartOptions.label[valorSelec]

            if (valorSelec !== undefined && nome !== undefined) {
              router.push({ path: `${nome}/visualizar` })
            }
          },
        },
      },
      plotOptions: {
        bar: {
          horizontal: false,
          borderRadius: 10,
          columnWidth: '45%',
          dataLabels: {
            total: {
              enabled: true,
              style: {
                fontSize: '13px',
                fontWeight: 900,
              },
            },
          },
        },
      },
      xaxis: {
        type: String,
        categories: colunas,
      },
      legend: {
        position: 'right',
        offsetY: 40,
        inverseOrder: true,
      },
      fill: {
        opacity: 1,
      },
      responsive: [{
        breakpoint: 480,
        options: {
          legend: {
            position: 'bottom',
            offsetX: -10,
            offsetY: 0,
          },
        },
      }],
    },
  })

  api.getContadoresCnd()
    .then(payload => {
      const permissoes = localStorage.getItem('permissoes')
      const dadosData = payload.data
      const motivos = []
      const regular = []
      const irregular = []

      if (permissoes.includes('cnd_federal')) {
        motivos.push(dadosData.rfbPgfn.motivo)
        regular.push(dadosData.rfbPgfn.regular)
        irregular.push(dadosData.rfbPgfn.irregular)
      }
      if (permissoes.includes('cnd_fgts')) {
        motivos.push(dadosData.fgts.motivo)
        regular.push(dadosData.fgts.regular)
        irregular.push(dadosData.fgts.irregular)
      }
      if (permissoes.includes('cnd_trabalhista')) {
        motivos.push(dadosData.trabalhista.motivo)
        regular.push(dadosData.trabalhista.regular)
        irregular.push(dadosData.trabalhista.irregular)
      }
      // if (permissoes.includes('cnd_mte')) {
      //   motivos.push(dadosData.mte.motivo)
      //   regular.push(dadosData.mte.regular)
      //   irregular.push(dadosData.mte.irregular)
      // }
      if (permissoes.includes('cnd_estadual')) {
        motivos.push(dadosData.estadual.motivo)
        regular.push(dadosData.estadual.regular)
        irregular.push(dadosData.estadual.irregular)
      }
      if (permissoes.includes('cnd_municipal')) {
        motivos.push(dadosData.municipal.motivo)
        regular.push(dadosData.municipal.regular)
        irregular.push(dadosData.municipal.irregular)
      }

      dados.value.series = [
        { name: 'Motivo', data: motivos },
        { name: 'Regular', data: regular },
        { name: 'Irregular', data: irregular },
      ]
    })
  return {
    dadosCnds: dados,
  }

  function getLabels() {
    const permissoes = localStorage.getItem('permissoes')
    const label = []

    if (permissoes.includes('cnd_federal')) {
      label.push('cnd-federal')
    }
    if (permissoes.includes('cnd_fgts')) {
      label.push('cnd-fgts')
    }
    if (permissoes.includes('cnd_trabalhista')) {
      label.push('cnd-trabalhista')
    }
    // if (permissoes.includes('cnd_mte')) {
    //   label.push('cnd-mte')
    // }
    if (permissoes.includes('cnd_estadual')) {
      label.push('cnd-estadual')
    }
    if (permissoes.includes('cnd_municipal')) {
      label.push('cnd-municipal')
    }

    return label
  }

  function getColunas() {
    const permissoes = localStorage.getItem('permissoes')
    const colunasValidas = []

    if (permissoes.includes('cnd_federal')) {
      colunasValidas.push('RFB/PGFN')
    }
    if (permissoes.includes('cnd_fgts')) {
      colunasValidas.push('FGTS')
    }
    if (permissoes.includes('cnd_trabalhista')) {
      colunasValidas.push('Trabalhista')
    }
    // if (permissoes.includes('cnd_mte')) {
    //   colunasValidas.push('MTE')
    // }
    if (permissoes.includes('cnd_estadual')) {
      colunasValidas.push('Estadual')
    }
    if (permissoes.includes('cnd_municipal')) {
      colunasValidas.push('Municipal')
    }

    return colunasValidas
  }
}
