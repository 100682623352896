import { ref } from '@vue/composition-api'
import { useRouter } from '@core/utils/utils'
import api from '../services/api'

export default function () {
  const { router } = useRouter()
  const situacaoAlterada = ['Situação com', 'alteração']

  const dados = ref({
    series: [{
      name: 'Quantidade',
      data: [],
    }],
    chartOptions: {
      label: ['SituacaoComAlteracao', 'OmissaoDCTFWeb', 'OmissaoDITR', 'OmissaoDASNSIMEI', 'OmissaoDEFIS', 'OmissaoDASN', 'OmissaoGFIP', 'OmissaoECF', 'OmissaoDCTF', 'OmissaoDIRF', 'OmissaoPGDAS', 'OmissaoEFD', 'ProcessoArrolamentoBensSief', 'ProcessoFiscalSief', 'ParcelamentoSipade', 'ParcelamentoParcsnParcmei', 'ParcelamentoSiefpar', 'PendenciaDividasAtivas', 'PendenciaDebitoSicob', 'PendenciaDebitoSief', 'PendenciaGFIPxGPS', 'IrregularidadeCadastral'],
      annotations: {
        points: [{
          x: 'Situação Fiscal',
          seriesIndex: 0,
          label: {
            borderColor: '#775DD0',
            offsetY: 0,
            style: {
              color: '#fff',
              background: '#775DD0',
            },
          },
        }],
      },
      chart: {
        height: 350,
        type: 'bar',
        events: {
          dataPointSelection(event, chartContext, grafico) {
            const status = dados.value.chartOptions.label[grafico.dataPointIndex]
            if (status !== undefined) {
              router.push({ path: 'situacao-fiscal/visualizar', query: { filtroRapido: status } })
            }
          },
          click(event) {
            const clickEvent = situacaoAlterada.includes(event.toElement.innerHTML) ? dados.value.chartOptions.xaxis.categories[0] : event.toElement.innerHTML
            const valorSelec = dados.value.chartOptions.xaxis.categories.indexOf(clickEvent)
            const status = dados.value.chartOptions.label[valorSelec]
            if (status !== undefined) {
              router.push({ path: 'situacao-fiscal/visualizar', query: { filtroRapido: status } })
            }
          },
        },
      },
      plotOptions: {
        bar: {
          borderRadius: 10,
          columnWidth: '50%',
        },
      },
      dataLabels: {
        enabled: false,
      },
      stroke: {
        width: 2,
      },

      grid: {
        row: {
          colors: ['#fff', '#f2f2f2'],
        },
      },
      xaxis: {
        labels: {
          rotate: -46,
          style: {
            fontSize: '10px',
          },
        },
        type: String,
        categories: [situacaoAlterada, 'Omissão DCTFWeb', 'Omissão DITR', 'Omissão DASN SIMEI', 'Omissão DEFIS', 'Omissão DASN', 'Omissão GFIP', 'Omissão ECF', 'Omissão DCTF', 'Omissão DIRF', 'Omissão PGDAS', 'Omissão EFD', 'Proces. Arrolamento Bens', 'Processo Fiscal Sief', 'Parc. SIPADE', 'Parc. PARCSN/PARCMEI', 'Parc. SIEFPAR', 'Divida Ativa', 'Débito Sicob', 'Débito Sief', 'Div. GFIP xGPS', 'Irregularidade Cadastral'],
        tickPlacement: 'on',
      },
      yaxis: {
        title: {
          text: 'Quantidades',
          style: {
            fontSize: '15px',
            fontWeight: 600,
            cssClass: 'apexcharts-yaxis-title yaxis-espacamento-titulo',
          },
        },
      },
      fill: {
        type: 'gradient',
        gradient: {
          shade: 'light',
          type: 'horizontal',
          shadeIntensity: 0.25,
          gradientToColors: ['#315e9e'],
          inverseColors: false,
          opacityFrom: 0.70,
          opacityTo: 1.00,
          stops: [50, 0, 100],
        },
      },
    },
  })

  api.getContadoresPendenciaSituacaoFiscal()
    .then(payload => {
      const valores = payload.data
      dados.value.series = [{
        data: [
          valores.situacaoComAlteracao,
          valores.omissaoDCTFWeb,
          valores.omissaoDITR,
          valores.omissaoDASNSIMEI,
          valores.omissaoDEFIS,
          valores.omissaoDASN,
          valores.omissaoGFIP,
          valores.omissaoECF,
          valores.omissaoDCTF,
          valores.omissaoDIRF,
          valores.omissaoPGDAS,
          valores.omissaoEFD,
          valores.processoArrolamentoBensSief,
          valores.processoFiscalSief,
          valores.parcelamentoSipade,
          valores.parcelamentoParcsnParcmei,
          valores.parcelamentoSiefpar,
          valores.pendenciaDividasAtivas,
          valores.pendenciaDebitoSicob,
          valores.pendenciaDebitoSief,
          valores.pendenciaGFIPxGPS,
          valores.irregularidadeCadastral,
        ],
      }]
    })
  return {
    dadosPendenciaSituacaoFiscal: dados,
  }
}
