import { useRouter } from '@core/utils/utils'
import { ref } from '@vue/composition-api'
import api from '../services/api'

export default function (anoInicial) {
  const { router } = useRouter()
  const ano = ref(anoInicial)
  const dados = ref({
    series: [{
      name: 'Ativos',
      data: [],
      // color: 'rgba(25, 48, 81, 0.75)',
      // color: 'rgba(77, 159, 114, 0.75)',
      color: 'rgba(77, 159, 114)',
    },
    {
      name: 'Em Andamento',
      data: [],
      color: 'rgba(49, 94, 158)',
      // color: 'rgba(255, 94, 110)',
    },
    {
      name: 'Outros',
      data: [],
      color: 'rgba(247, 210, 74)',
      // color: 'rgba(237, 125, 62)',
      // color: 'rgba(206, 212, 49)',
    }],
    chartOptions: {
      chart: {
        stacked: true,
        events: {
          dataPointSelection(event, chartContext, grafico) {
            // esse é o click nos valores do gráfico
            const eixoX = grafico.w.config.series[grafico.seriesIndex].data[grafico.dataPointIndex].x
            const grupo = grafico.w.config.series[grafico.seriesIndex].name
            // const filtroAtrasados = grafico.w.config.series[grafico.seriesIndex].name === 'Em atraso'
            // query: { filtroRapido: filtroAtrasados ? 'StatusEmAtraso' : 'StatusSemAtraso' }

            // filtroRapido=Ativa
            if (eixoX === 'JAN') {
              router.push({ path: 'declaracao-dctf-web/visualizar', query: { filtroMes: `${ano.value}-01`, filtroRapido: grupo } })
            } else if (eixoX === 'FEV') {
              router.push({ path: 'declaracao-dctf-web/visualizar', query: { filtroMes: `${ano.value}-02`, filtroRapido: grupo } })
            } else if (eixoX === 'MAR') {
              router.push({ path: 'declaracao-dctf-web/visualizar', query: { filtroMes: `${ano.value}-03`, filtroRapido: grupo } })
            } else if (eixoX === 'ABR') {
              router.push({ path: 'declaracao-dctf-web/visualizar', query: { filtroMes: `${ano.value}-04`, filtroRapido: grupo } })
            } else if (eixoX === 'MAI') {
              router.push({ path: 'declaracao-dctf-web/visualizar', query: { filtroMes: `${ano.value}-05`, filtroRapido: grupo } })
            } else if (eixoX === 'JUN') {
              router.push({ path: 'declaracao-dctf-web/visualizar', query: { filtroMes: `${ano.value}-06`, filtroRapido: grupo } })
            } else if (eixoX === 'JUL') {
              router.push({ path: 'declaracao-dctf-web/visualizar', query: { filtroMes: `${ano.value}-07`, filtroRapido: grupo } })
            } else if (eixoX === 'AGO') {
              router.push({ path: 'declaracao-dctf-web/visualizar', query: { filtroMes: `${ano.value}-08`, filtroRapido: grupo } })
            } else if (eixoX === 'SET') {
              router.push({ path: 'declaracao-dctf-web/visualizar', query: { filtroMes: `${ano.value}-09`, filtroRapido: grupo } })
            } else if (eixoX === 'OUT') {
              router.push({ path: 'declaracao-dctf-web/visualizar', query: { filtroMes: `${ano.value}-10`, filtroRapido: grupo } })
            } else if (eixoX === 'NOV') {
              router.push({ path: 'declaracao-dctf-web/visualizar', query: { filtroMes: `${ano.value}-11`, filtroRapido: grupo } })
            } else if (eixoX === 'DEZ') {
              router.push({ path: 'declaracao-dctf-web/visualizar', query: { filtroMes: `${ano.value}-12`, filtroRapido: grupo } })
            } else if (eixoX === 'ANUAL') {
              router.push({ path: 'declaracao-dctf-web/visualizar', query: { filtroAno: `${ano.value}`, filtroRapido: grupo } })
            }
          },
          click(event) {
            // esse é o click na base do gráfico
            const eixoX = event.toElement.innerHTML
            if (eixoX === 'JAN') {
              router.push({ path: 'declaracao-dctf-web/visualizar', query: { filtroMes: `${ano.value}-01` } })
            } else if (eixoX === 'FEV') {
              router.push({ path: 'declaracao-dctf-web/visualizar', query: { filtroMes: `${ano.value}-02` } })
            } else if (eixoX === 'MAR') {
              router.push({ path: 'declaracao-dctf-web/visualizar', query: { filtroMes: `${ano.value}-03` } })
            } else if (eixoX === 'ABR') {
              router.push({ path: 'declaracao-dctf-web/visualizar', query: { filtroMes: `${ano.value}-04` } })
            } else if (eixoX === 'MAI') {
              router.push({ path: 'declaracao-dctf-web/visualizar', query: { filtroMes: `${ano.value}-05` } })
            } else if (eixoX === 'JUN') {
              router.push({ path: 'declaracao-dctf-web/visualizar', query: { filtroMes: `${ano.value}-06` } })
            } else if (eixoX === 'JUL') {
              router.push({ path: 'declaracao-dctf-web/visualizar', query: { filtroMes: `${ano.value}-07` } })
            } else if (eixoX === 'AGO') {
              router.push({ path: 'declaracao-dctf-web/visualizar', query: { filtroMes: `${ano.value}-08` } })
            } else if (eixoX === 'SET') {
              router.push({ path: 'declaracao-dctf-web/visualizar', query: { filtroMes: `${ano.value}-09` } })
            } else if (eixoX === 'OUT') {
              router.push({ path: 'declaracao-dctf-web/visualizar', query: { filtroMes: `${ano.value}-10` } })
            } else if (eixoX === 'NOV') {
              router.push({ path: 'declaracao-dctf-web/visualizar', query: { filtroMes: `${ano.value}-11` } })
            } else if (eixoX === 'DEZ') {
              router.push({ path: 'declaracao-dctf-web/visualizar', query: { filtroMes: `${ano.value}-12` } })
            } else if (eixoX === 'ANUAL') {
              router.push({ path: 'declaracao-dctf-web/visualizar', query: { filtroAno: `${ano.value}` } })
            }
          },
        },
      },
      // dataLabels: {
      //   style: {
      //     colors: ['#28c76f', '#ea5455'],
      //   },
      // },
      xaxis: {
        categories: ['JAN', 'FEV', 'MAR', 'ABR', 'MAI', 'JUN', 'JUL', 'AGO', 'SET', 'OUT', 'NOV', 'DEZ', 'ANUAL'],
      },
      plotOptions: {
        bar: {
          borderRadius: 5,
        },
      },
    },
  })

  api.getContadoresDctfWeb(ano.value)
    .then(payload => {
      dados.value.series = [{
        name: 'Ativa',
        data: payload.data.dctfWebAtivos,
      }, {
        name: 'Em Andamento',
        data: payload.data.dctfWebEmAndamento,
      },
      {
        name: 'Outros',
        data: payload.data.dctfWebOutros,
      }]
      dados.value.total = payload.data.total
    })

  return {
    dadosDctfWebStatus: dados,
    anoDash: ano,
  }
}
